.grecaptcha-badge {
    display: none
}

.picture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.picture.contain-cover {
    -o-object-fit: contain;
    object-fit: contain
}

.picture-block {
    position: relative
}

.aligncenter {
    max-width: 100%
}

.front-slider-list__element-screen.tablet {
    border-radius: 5px
}

.front-slider-list__element-screen.mobile {
    border-radius: 10px
}

.hide {
    display: none!important
}

.footer-foot.footer-space-around {
    justify-content: space-between
}

.description img {
    width: auto;
    height: auto
}

.description .description-clipped picture img {
    width: auto;
    height: auto;
    max-width: 100%
}

.article-toc {
    display: none
}

.article-toc * {
    box-sizing: border-box
}

.article-toc.active {
    display: block
}

.article-toc.active .article-toc__inner {
    width: 100%;
    max-width: 427px;
    border: 3px solid #f5f5f5;
    border-radius: 18px;
    padding: 26px 25px 10px
}

.article-toc.active .toc-is-close {
    display: none
}

.article-toc.active .toc-is-open {
    display: block;
    padding: 8px 0 0
}

.article-toc.active .toc-toggler {
    margin: 28px 0 0;
    position: relative;
    text-align: center
}

.article-toc.active .toc-toggler:before {
    display: block;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid #222;
    transform: rotate(45deg);
    border-right: 0;
    border-bottom: 0;
    top: 0;
    left: 50%;
    margin-left: -5px
}

.article-toc.active .toc-toggler:hover {
    color: #ff3644
}

.article-toc.active .toc-toggler:hover:before {
    border-color: #ff3644
}

.article-toc.active ol {
    counter-reset: li;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.article-toc.active ol>li {
    padding: 0;
    display: block
}

.article-toc.active ol>li:before {
    display: none
}

.article-toc.active ol>li+li {
    margin-top: 24px
}

.article-toc.active ol>li a {
    font: 400 14px/17px Lato;
    color: #222;
    letter-spacing: 0
}

.article-toc.active ol>li a:before {
    border-top: 0;
    font-weight: 400;
    counter-increment: li;
    content: counters(li, ".") ". "
}

.article-toc.active ol>li a:hover {
    color: #ff3644;
    -webkit-text-decoration: underline dashed;
    text-decoration: underline dashed
}

.article-toc.active ol ol {
    margin: 20px 0 0 13px
}

.article-toc.active ol ol>li {
    height: auto;
    position: relative
}

.article-toc.active ol ol>li:before {
    display: none
}

.article-toc.active ol ol>li a {
    color: #686868;
    padding-left: 10px;
    position: relative;
    display: block
}

.article-toc.active ol ol>li a:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #686868;
    padding: 0;
    display: block;
    position: absolute;
    left: 0;
    top: 8px
}

.article-toc.active ol ol>li a:hover:before {
    background: #ff3644
}

.article-toc.active+* {
    margin-top: 40px
}

@media (max-width:1023px) {
    .article-toc.active {
        display: block
    }
    .article-toc.active ol {
        margin: 0
    }
    .article-toc.active+* {
        margin-top: 30px
    }
    .article-toc.active>*+* {
        margin-top: 20px
    }
}

@media (max-width:767px) {
    .article-toc.active {
        margin: 0
    }
    .article-toc.active .article-toc__inner {
        padding: 28px 15px 15px
    }
}

.header-search-icon {
    font-size: 0
}

.img-cover {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.img-cover-container-rel {
    position: relative
}

.img-cover-container-rel .img-cover {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto
}

.front-thumb-list__item-cont {
    overflow: hidden
}

.page-date {
    color: #686868;
    font: 400 14px/17px Lato
}

.page-date time {
    color: #222
}

.page-date+.article-toc {
    margin-top: 30px
}

@media (max-width:767px) {
    .page-date+.article-toc {
        margin-top: 20px
    }
}

.topbg {
    top: inherit
}
.bonus__block {
    color: #fff;
    margin: 0 auto 70px;
    max-width: 580px;
    width: 100%;
}

.bonus__block .title-bonus {
    font-size: 23px;
    line-height: 1.4em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.bonus__block .title-bonus span.vl {
    color: #00ebea;
    font-size: 34px;
    line-height: 1.17em;
    display: block;
    white-space: nowrap;
}

.bonus__block a:hover {
    color: #fff;
}

@media (max-width: 1264 px) {
    .bonus__block {
        max-width: 350px;
    }
}

@media (max-width: 752px) {
    .bonus__block {
        margin: 0 auto 24px;
        max-width: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    .bonus__block:before {
        content: '';
        position: absolute;
        left: -16px;
        right: -16px;
        bottom: -30px;
        top: 0;
        display: block;
        background: #011a1b;
        z-index: -1;
    }
    .bonus__block .title-bonus span.vl {
        white-space: normal;
    }
    .bonus__block .title-bonus {
        font-size: 18px;
    }
}
