.wrapper, .header-content, .footer-content, .fixedbar-container {
  width: 1170px;
  margin: 0 auto;
  box-sizing: border-box
}

@media (max-width: 1279px) {
  .wrapper, .header-content, .footer-content, .fixedbar-container {
    width: 960px
  }
}

@media (max-width: 1023px) {
  .wrapper, .header-content, .footer-content, .fixedbar-container {
    width: auto;
    max-width: 1008px;
    padding: 0 24px
  }
}

@media (max-width: 767px) {
  .wrapper, .header-content, .footer-content, .fixedbar-container {
    padding: 0 16px
  }
}

body:after {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 20px;
  font-weight: bold;
  padding: 5px;
  z-index: 999;
  color: #000;
  text-shadow: 0 0 5px var(--white_color)
}

@media (min-width: 2560px) {
  body:after {
    content: "2560px"
  }
}

@media (max-width: 2559px) {
  body:after {
    content: "1920px"
  }
}

@media (max-width: 1919px) {
  body:after {
    content: "1280px"
  }
}

@media (max-width: 1279px) {
  body:after {
    content: "1024px"
  }
}

@media (max-width: 1023px) {
  body:after {
    content: "768px"
  }
}

@media (max-width: 767px) {
  body:after {
    content: "480px"
  }
}

@media (max-width: 479px) {
  body:after {
    content: "320px"
  }
}

ul > li, .fixedbar-menu ul, .fixedbar-menu ul li, .masonry-grid__item-list ul, .masonry-grid__item-list ul li {
  list-style: outside none none;
  padding: 0;
  margin: 0
}

* {
  outline: none !important
}

html, body, div, header, footer, ul, ol, li, h1, h2, h3, h4, table, tr, td, th, p, span, a, img, blockquote, button {
  margin: 0;
  padding: 0
}

html {
  position: relative
}

body {
  width: 100%;
  min-width: 320px;
  position: relative;
  font: 14px/20px "Lato", sans-serif;
  color: var(--dark);
  letter-spacing: 0.25px;
  background-color: var(--white_color);
  -webkit-overflow-scrolling: touch
}

img {
  border: none
}

a {
  cursor: pointer;
  color: var(--primary);
  text-decoration: underline;
  transition: all 0.15s ease-in-out
}

a:hover {
  color: var(--brown);
  text-decoration: none
}

@media (max-width: 1023px) {
  a:hover {
    color: var(--primary);
    text-decoration: none
  }
}

p {
  margin-bottom: 16px
}

h1, h2, h3, h4 {
  font: 700 48px/normal "Lato", sans-serif;
  color: var(--dark);
  letter-spacing: normal;
  margin-bottom: 40px
}

h1 {
  font-weight: 600;
  line-height: 58px
}

@media (max-width: 1023px) {
  h1 {
    font-size: 34px;
    line-height: 40px
  }
}

h2 {
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  margin-bottom: 24px
}

@media (max-width: 1023px) {
  h2 {
    font-size: 24px;
    line-height: 28px
  }
}

h3 {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 20px
}

@media (max-width: 1023px) {
  h3 {
    font-size: 20px;
    line-height: 26px
  }
}

h4 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.25px;
  margin-bottom: 20px
}

.h101 {
  display: block;
  font: 700 48px/normal "Lato", sans-serif;
  color: var(--dark);
  letter-spacing: normal;
  margin-bottom: 40px
}

.h101 {
  font-weight: 600;
  line-height: 58px
}

@media (max-width: 1023px) {
  .h101 {
    font-size: 34px;
    line-height: 40px
  }
}

button {
  height: 40px;
  cursor: pointer;
  font: 14px "Lato", sans-serif
}

button {
  font-family: "Lato", sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

button::-moz-focus-inner {
  border: 0 !important
}

ol {
  display: table;
  table-layout: fixed;
  margin-top: -18px;
  margin-bottom: 48px;
  counter-reset: list;
  list-style-type: none
}

@media (max-width: 1023px) {
  ol {
    margin-bottom: 40px
  }
}

@media (max-width: 767px) {
  ol {
    margin-bottom: 32px
  }
}

ol > li {
  display: table-row;
  padding-bottom: 20px
}

ol > li:before {
  display: table-cell
}

ol > li:before {
  border-top: 20px solid transparent;
  counter-increment: list;
  content: counter(list) ".";
  font-weight: 700;
  padding-right: 6px;
  text-align: right
}

ol > li ol {
  display: table;
  margin: 0;
  padding: 0
}

ol > li ol > li:before {
  content: counters(list, ".") "."
}

ul {
  padding-left: 0;
  margin-bottom: 48px
}

@media (max-width: 1023px) {
  ul {
    margin-bottom: 40px
  }
}

@media (max-width: 767px) {
  ul {
    margin-bottom: 32px
  }
}

ul > li {
  padding: 2px 0 2px 32px;
  margin-bottom: 24px;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden
}

@media (max-width: 1023px) {
  ul > li {
    margin-bottom: 20px
  }
}

ul > li:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 12px;
  left: 12px;
  border: 2px solid #3DD4DB;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

ul > li:last-child {
  margin-bottom: 0
}

blockquote {
  margin: 32px 0;
  padding-left: 24px;
  border-left: 4px solid var(--primary);
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px
}

@media (max-width: 1023px) {
  blockquote {
    width: calc(100% + 24px);
    margin: 32px 0 32px -24px
  }
}

@media (max-width: 767px) {
  blockquote {
    width: calc(100% + 16px);
    margin: 34px 0 32px -16px;
    padding-left: 20px
  }
}

blockquote > *:last-child {
  margin-bottom: 0
}

table {
  width: auto;
  max-width: 100%;
  border-collapse: separate;
  border-spacing: 2px
}

table th, table td {
  width: 138px;
  padding: 14px 15px;
  background-color: var(--grey);
  border-right: 0;
  border-bottom: 0;
  text-align: left;
  vertical-align: top;
  border-radius: 12px;
  font-weight: normal
}

table th {
  background-color: var(--brown);
  color: var(--grey)
}

.topbg {
  width: 100%;
  height: 0;
  background-color: var(--backgounds_color);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity .3s ease-in-out, height .3s ease-in-out
}

.wrapper {
  padding-top: 8px;
  padding-bottom: 42px;
  position: relative;
  z-index: 100;
  padding-right: 330px;
  opacity: 0;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  transition: opacity .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transition-delay: .9s
}

@media (max-width: 1279px) {
  .wrapper {
    padding-right: 312px
  }
}

@media (max-width: 1023px) {
  .wrapper {
    padding: 12px 24px 40px
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 14px 16px 32px
  }
}

@media (max-width: 1023px) {
  .wrapper_main {
    padding-top: 0
  }
}

.wrapper > *:last-child {
  margin-bottom: 0
}

.wrapper .title-page {
  margin-bottom: 40px;
  color: var(--white_color);
  position: relative;
  text-align: center
}

@media (max-width: 1279px) {
  .wrapper .title-page {
    font-size: 34px;
    line-height: 40px
  }
}

@media (max-width: 1023px) {
  .wrapper .title-page {
    margin-bottom: 32px
  }
}

@media (max-width: 767px) {
  .wrapper .title-page {
    margin-bottom: 24px
  }
}

.wrapper .title-page_main {
  padding: 8px 78px 0
}

@media (max-width: 1279px) {
  .wrapper .title-page_main {
    padding: 8px 72px 0;
    margin-bottom: 32px
  }
}

@media (max-width: 767px) {
  .wrapper .title-page_main {
    padding: 16px 50px 0;
    margin-bottom: 24px
  }
}

.wrapper .title-page .game-rating {
  display: inline-block;
  margin-left: 18px;
  cursor: pointer;
  position: absolute;
  bottom: 22px;
  color: var(--dark);
  text-decoration: none
}

@media (max-width: 1279px) {
  .wrapper .title-page .game-rating {
    height: 32px;
    margin-left: 12px;
    bottom: 10px
  }
  .wrapper .title-page .game-rating:before {
    background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ctitle%3Estar_sm_icon_1%3C/title%3E%3Cpath d='M10.001 12.857l2.91 2.03a.586.586 0 0 0 .91-.635l-1.112-3.277 2.873-1.961a.565.565 0 0 0-.343-1.032h-3.523l-1.147-3.424a.599.599 0 0 0-1.123 0l-1.16 3.424H4.764a.565.565 0 0 0-.343 1.032l2.874 1.961-1.112 3.277a.587.587 0 0 0 .91.635z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px
  }
}

@media (max-width: 767px) {
  .wrapper .title-page .game-rating {
    margin-left: 8px
  }
}

.header {
  opacity: 0;
  position: relative;
  transition: opacity .3s ease-in-out;
  transition-delay: .3s
}

@media (max-width: 1023px) {
  .header {
    height: 80px
  }
}

@media (max-width: 767px) {
  .header {
    height: 64px
  }
}

.header-content {
  height: 80px;
  padding: 16px 402px 0 0;
  box-sizing: border-box;
  background-color: #011a1b;
  position: relative;
  z-index: 600
}

@media (max-width: 1279px) {
  .header-content {
    height: 64px;
    padding: 8px 322px 0 0
  }
}

@media (max-width: 1023px) {
  .header-content {
    height: 80px;
    padding: 27px 104px 0 24px;
    z-index: 400
  }
}

@media (max-width: 767px) {
  .header-content {
    height: 64px;
    padding: 21px 56px 0 16px
  }
}

.header-search {
  margin-top: -48px;
  margin-left: 72px;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out
}

@media (max-width: 1023px) {
  .header-search {
    display: none;
    transition: none
  }
}

.header-assist {
  width: 288px;
  height: 130px;
  padding-top: 30px;
  box-sizing: border-box;
  background-color: var(--backgounds_color);
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transition-delay: .6s
}

@media (max-width: 1279px) {
  .header-assist {
    height: 114px
  }
}

@media (max-width: 1023px) {
  .header-assist {
    width: auto;
    height: auto;
    padding: 0;
    top: 20px;
    right: 24px
  }
}

@media (max-width: 767px) {
  .header-assist {
    top: 16px;
    right: 16px
  }
}

.header-assist:before, .header-assist:after {
  content: '';
  display: block;
  position: absolute
}

@media (max-width: 1023px) {
  .header-assist:before, .header-assist:after {
    display: none
  }
}

.header-assist:before {
  height: 24px;
  background: linear-gradient(180deg, var(--backgounds_color) 0%, transparent 100%);
  top: 100%;
  right: -12px;
  left: 0
}

.header-assist:after {
  width: 12px;
  background-color: var(--backgounds_color);
  top: 0;
  bottom: 0;
  left: 100%
}

.header-assist-ava {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  background: url("../images/732676eadb7c42bf665eb2f7957a0d36.png");
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  position: relative
}

@media (max-width: 1023px) {
  .header-assist-ava {
    width: 40px;
    height: 40px;
    margin: 0
  }
}

@media (max-width: 767px) {
  .header-assist-ava {
    width: 32px;
    height: 32px
  }
}

.header-assist-ava:before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--green);
  position: absolute;
  top: -6px;
  right: -6px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s ease-in-out
}

.header-assist-info {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  padding-top: 2px
}

@media (max-width: 1023px) {
  .header-assist-info {
    display: none
  }
}

.header-assist-info-name {
  max-height: 56px;
  overflow: hidden;
  margin-bottom: 6px;
  color: var(--white_color)
}

.header-assist-info-name a {
  color: var(--white_color);
  text-decoration: none
}

.header-assist-info-name a:after {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ctitle%3Eexternal_link%3C/title%3E%3Cpath d='M7 1.5v1h1.795L3.88 7.415l.705.705L9.5 3.205V5h1V1.5zm2.5 8h-7v-7H6v-1H2.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6h-1z' fill='%23fff' opacity='.7'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  position: relative;
  bottom: 8px;
  transition: opacity 0.15s ease-in-out
}

.header-assist-info-name a:hover {
  color: var(--white_light_2)
}

.header-assist-info-name a:hover:after {
  opacity: .5
}

.header-assist-info-status {
  font-size: 14px;
  line-height: 20px;
  color: var(--white_light_3);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.header-assist.indication .header-assist-ava:before {
  opacity: 1;
  visibility: visible
}

.body_show .topbg {
  opacity: 1;
  height: 450px
}

@media (max-width: 1279px) {
  .body_show .topbg {
    height: 400px
  }
}

@media (max-width: 1008px) {
  .body_show .topbg {
    height: 408px
  }
}

@media (max-width: 767px) {
  .body_show .topbg {
    height: 385px
  }
}

.body_show .header {
  opacity: 1
}

.body_show .header-assist {
  opacity: 1
}

.body_show .chat, .body_show .footer, .body_show .wrapper {
  opacity: 1
}

.body_show .wrapper {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.body_fixedbar .fixedbar {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  box-shadow: var(--light_shadow)
}

.body_fixedbar .header-content {
  opacity: 0;
  transition: opacity 0.15s ease-in-out
}

.footer {
  background-color: var(--backgounds_color);
  color: var(--white_light_1);
  position: relative;
  z-index: 100;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transition-delay: .9s
}

.footer-content {
  padding-top: 48px;
  padding-bottom: 32px
}

@media (max-width: 1023px) {
  .footer-content {
    padding-top: 32px
  }
}

@media (max-width: 767px) {
  .footer-content {
    padding-top: 16px;
    padding-bottom: 25px
  }
}

.footer-txt {
  margin-bottom: 48px
}

@media (max-width: 1023px) {
  .footer-txt {
    margin-bottom: 32px
  }
}

.footer-txt > *:last-child {
  margin-bottom: 0
}

.footer-foot {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 767px) {
  .footer-foot {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.footer-foot-cont {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 767px) {
  .footer-foot-cont {
    width: 100%;
    margin: 0
  }
}

.footer-foot-cop {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 27px;
  color: var(--white_light_2)
}

@media (max-width: 767px) {
  .footer-foot-cop {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    margin-right: 9px
  }
}

.footer-foot-age {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.footer-foot-age img {
  display: block;
  opacity: .5
}

.game {
  position: relative
}

.game-img {
  margin-bottom: 12px;
  border-radius: 18px;
  background-color: var(--grey);
  position: relative;
  top: 0;
  overflow: hidden;
  transition: top 0.15s ease-in-out
}

.game-img .img-cover-container-rel {
  display: block;
  height: 0;
  padding-top: 58.33%;
  background-position: 50% 50%;
  background-size: cover
}

.game-name {
  padding: 0 50px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center
}

.game-name a {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  overflow: hidden;
  color: var(--dark);
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis
}

.game-rating {
  display: inline-block;
  height: 36px;
  padding: 0 8px 0 28px;
  background: var(--accent);
  box-shadow: 0 4px 8px var(--accent_light);
  border-radius: 16px 16px 16px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 1.25px;
  color: var(--dark);
  position: relative
}

@media (max-width: 1023px) {
  .game-rating {
    height: 32px;
    padding: 0 8px 0 24px;
    line-height: 32px
  }
}

.game-rating:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24'%3E%3Ctitle%3Estar_sm_icon%3C/title%3E%3Cpath d='M12.66 15.429l3.49 2.435a.703.703 0 0 0 1.093-.762L15.91 13.17l3.448-2.354a.678.678 0 0 0-.412-1.238h-4.228L13.34 5.47a.719.719 0 0 0-1.348 0l-1.39 4.11H6.374a.678.678 0 0 0-.411 1.237L9.41 13.17l-1.334 3.932a.705.705 0 0 0 1.093.762z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 25px;
  height: 24px;
  position: absolute;
  top: 6px;
  left: 4px
}

@media (max-width: 1023px) {
  .game-rating:before {
    background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ctitle%3Estar_sm_icon_1%3C/title%3E%3Cpath d='M10.001 12.857l2.91 2.03a.586.586 0 0 0 .91-.635l-1.112-3.277 2.873-1.961a.565.565 0 0 0-.343-1.032h-3.523l-1.147-3.424a.599.599 0 0 0-1.123 0l-1.16 3.424H4.764a.565.565 0 0 0-.343 1.032l2.874 1.961-1.112 3.277a.587.587 0 0 0 .91.635z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px
  }
}

.game .game-rating {
  position: absolute;
  top: -12px;
  right: -12px;
  transition: top 0.15s ease-in-out
}

.game:hover .game-img {
  top: -4px
}

.game:hover .game-name a {
  color: var(--primary)
}

.game:hover .game-rating {
  top: -16px
}

@media (max-width: 1023px) {
  .game:hover .game-img {
    top: 0
  }
  .game:hover .game-name a {
    color: var(--dark)
  }
  .game:hover .game-rating {
    top: -12px
  }
}

a.button {
  display: inline-block;
  height: 48px;
  padding: 0 22px;
  box-sizing: border-box;
  border: 2px solid var(--primary);
  border-radius: 24px;
  background-color: transparent;
  background-clip: border-box;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 44px;
  color: var(--primary);
  letter-spacing: 1.25px;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-color 0.15s ease-in-out
}

@media (max-width: 1023px) {
  a.button {
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 36px;
    border-radius: 16px
  }
}

a.button:hover {
  background-color: var(--primary);
  color: var(--white_color)
}

@media (max-width: 1023px) {
  a.button:hover {
    background-color: transparent;
    color: var(--primary)
  }
}

a.button_primary-bg {
  background-color: var(--primary);
  color: var(--white_color);
  border-color: transparent
}

a.button_primary-bg:hover {
  background-color: var(--brown)
}

@media (max-width: 1023px) {
  a.button_primary-bg:hover {
    background-color: var(--primary);
    color: var(--white_color)
  }
}

a.button_accent-bg {
  background-color: var(--accent);
  border-color: transparent;
  color: var(--brown)
}

a.button_accent-bg:hover {
  background-color: var(--brown);
  color: var(--white_color)
}

@media (max-width: 1023px) {
  a.button_accent-bg:hover {
    background-color: var(--accent);
    color: var(--brown)
  }
}

.chat {
  width: 312px;
  padding: 0 12px;
  overflow: hidden;
  margin-right: -597px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 50%;
  bottom: 0;
  z-index: 500;
  opacity: 0
}

@media (max-width: 1279px) {
  .chat {
    margin-right: -492px
  }
}

@media (max-width: 1023px) {
  .chat {
    width: 336px;
    padding-top: 64px;
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    right: 0;
    z-index: 700;
    opacity: 1;
    background-color: var(--white_color);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    transition: -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition-delay: 0s;
    display: none
  }
}

@media (max-width: 767px) {
  .chat {
    width: 100%;
    right: 0;
    -webkit-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    transform: translate(0, 100%)
  }
}

.chat-head {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 64px;
  padding: 0 16px;
  box-sizing: border-box;
  background: var(--white_color);
  box-shadow: var(--light_shadow);
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 1023px) {
  .chat-head {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }
}

.chat-head:before {
  content: '';
  width: 32px;
  height: 4px;
  border-radius: 20px;
  background-color: var(--dark_light_4);
  position: absolute;
  top: 4px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
  display: none
}

@media (max-width: 767px) {
  .chat-head:before {
    display: block
  }
}

.chat-head .header-assist {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-width: 0;
  position: static;
  background-color: transparent
}

.chat-head .header-assist:before {
  display: none
}

.chat-head .header-assist-ava {
  margin-right: 16px
}

@media (max-width: 767px) {
  .chat-head .header-assist-ava {
    margin-right: 12px
  }
}

.chat-head .header-assist-info {
  display: block;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0
}

@media (max-width: 767px) {
  .chat-head .header-assist-info {
    text-align: center
  }
}

.chat-head .header-assist-info-name {
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark)
}

.chat-head .header-assist-info-status {
  margin-top: 2px;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark_light_2)
}

.chat-content {
  width: calc(100% + 42px);
  height: 100%;
  padding: 0 30px 0 12px;
  margin-left: -12px;
  opacity: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 1023px) {
  .chat-content {
    width: 100%;
    padding: 16px;
    margin: 0
  }
}

.chat-content-box {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 288px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 1023px) {
  .chat-content-box {
    width: 100%
  }
}

.chat-content-section:first-child {
  margin-top: 160px
}

@media (max-width: 1023px) {
  .chat-content-section:first-child {
    margin-top: 0
  }
}

.chat-massages {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 8px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

.chat-massages__item {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  background-color: var(--grey);
  border-radius: 0 22px 22px;
  transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out
}

@media (max-width: 1023px) {
  .chat-massages__item {
    padding: 12px;
    border-radius: 0 16px 16px
  }
}

.chat-massages__item > *:last-child {
  margin-bottom: 0
}

.chat-close {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  display: block;
  border: none;
  margin-left: 32px
}

@media (max-width: 767px) {
  .chat-close {
    margin-left: 28px
  }
}

.chat-action {
  margin-bottom: 24px
}

.chat-action-block {
  margin-bottom: 16px;
  text-align: center
}

.chat-action-block-img {
  width: 100%;
  height: 0;
  margin-bottom: 12px;
  padding-top: 56.25%;
  border-radius: 16px;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: var(--dark_shadow)
}

.chat-action-block-tx {
  padding: 0 24px;
  line-height: 20px
}

.chat-action-block-tx span {
  display: inline-block;
  vertical-align: top;
  padding: 0 6px;
  background-color: var(--white_color);
  border-radius: 10px
}

.chat-action > .button {
  display: block;
  width: 100%;
  height: auto;
  line-height: 19px;
  padding-top: 13px;
  padding-bottom: 12px
}

@media (max-width: 1023px) {
  .chat-action > .button {
    padding-top: 9px;
    padding-bottom: 8px
  }
}

.chat-action > .button_primary-bg {
  box-shadow: var(--button_shadow)
}

.chat-action > .button_primary-bg:hover {
  box-shadow: none
}

@media (max-width: 1023px) {
  .chat-action > .button_primary-bg:hover {
    box-shadow: var(--button_shadow)
  }
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--black_light_4);
  z-index: 680;
  display: none;
  opacity: 0;
  transition: opacity 0.15s ease-in-out
}

.fixedbar {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 650;
  background-color: var(--white_color);
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out
}

@media (max-width: 1023px) {
  .fixedbar {
    background-color: var(--backgounds_color);
    box-shadow: none
  }
}

.fixedbar-container {
  padding-top: 12px;
  padding-bottom: 12px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 1023px) {
  .fixedbar-container {
    padding-top: 8px;
    padding-bottom: 8px;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
}

@media (max-width: 767px) {
  .fixedbar-container {
    padding-top: 10px;
    padding-bottom: 10px
  }
}

.fixedbar-container > .button_primary-bg {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: block;
  margin-right: 24px;
  height: 36px;
  padding: 0 12px;
  font-size: 14px;
  line-height: 32px
}

@media (max-width: 1023px) {
  .fixedbar-container > .button_primary-bg {
    margin: 0 24px
  }
}

.fixedbar-container .header-assist {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: auto;
  margin-left: auto;
  padding: 0;
  position: static;
  background-color: transparent
}

@media (max-width: 1023px) {
  .fixedbar-container .header-assist {
    margin-left: 32px
  }
}

@media (max-width: 767px) {
  .fixedbar-container .header-assist {
    margin-left: 16px
  }
}

.fixedbar-container .header-assist:before, .fixedbar-container .header-assist:after {
  display: none
}

.fixedbar-container .header-assist-ava {
  width: 40px;
  height: 40px;
  margin-right: 16px
}

@media (max-width: 1023px) {
  .fixedbar-container .header-assist-ava {
    margin: 0
  }
}

@media (max-width: 767px) {
  .fixedbar-container .header-assist-ava {
    width: 32px;
    height: 32px
  }
}

.fixedbar-container .header-assist-info {
  padding: 0
}

.fixedbar-container .header-assist-info-name {
  width: 100%;
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark);
  white-space: nowrap;
  text-overflow: ellipsis
}

.fixedbar-container .header-assist-info-name a {
  color: var(--dark)
}

.fixedbar-container .header-assist-info-name a:after {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ctitle%3Eexternal_link%3C/title%3E%3Cpath d='M7 1.5v1h1.795L3.88 7.415l.705.705L9.5 3.205V5h1V1.5zm2.5 8h-7v-7H6v-1H2.5a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V6h-1z' fill='%23222' opacity='.5'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  bottom: 5px
}

.fixedbar-container .header-assist-info-name a:hover {
  color: var(--dark_light_2)
}

@media (max-width: 1023px) {
  .fixedbar-container .header-assist-info-name a:hover {
    color: var(--dark)
  }
}

.fixedbar-container .header-assist-info-status {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark_light_2)
}

.fixedbar-menu {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

@media (max-width: 1023px) {
  .fixedbar-menu {
    display: none
  }
}

.fixedbar-menu ul {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.fixedbar-menu ul li {
  margin-right: 24px;
  font-size: 14px;
  line-height: 20px
}

.fixedbar-menu ul li:before {
  display: none
}

.fixedbar-menu ul li a {
  color: var(--dark);
  text-decoration: none
}

.fixedbar-menu ul li a:hover {
  color: var(--primary)
}

.fixedbar-box {
  display: none;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 1023px) {
  .fixedbar-box {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
  }
}

@media (max-width: 1023px) {
  .fixedbar_filter {
    background-color: var(--white_color)
  }
}

.fixedbar_filter .fixedbar-container {
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

@media (max-width: 1023px) {
  .fixedbar_filter .fixedbar-container {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
  }
}

@media (max-width: 767px) {
  .fixedbar_filter .fixedbar-container {
    padding: 8px 16px
  }
}

.fixedbar_filter .fixedbar-container .header-assist {
  margin-left: 42px
}

@media (max-width: 1023px) {
  .fixedbar_filter .fixedbar-container .header-assist {
    margin-left: 24px
  }
}

@media (max-width: 767px) {
  .fixedbar_filter .fixedbar-container .header-assist {
    margin-left: 16px
  }
}

.fixedbar_filter-box {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  min-width: 0;
  padding-top: 2px
}

@media (max-width: 1023px) {
  .fixedbar_filter-box {
    padding-top: 0
  }
}

.fixedbar_filter-box .filter {
  margin: -8px 0;
  position: relative;
  z-index: 10
}

@media (max-width: 1023px) {
  .fixedbar_filter-box .filter {
    height: 32px;
    margin: 0 -8px 0 -24px
  }
}

@media (max-width: 767px) {
  .fixedbar_filter-box .filter {
    margin: 0 -8px 0 -16px
  }
}

.fixedbar_filter-box .filter:before {
  content: '';
  width: 24px;
  background: linear-gradient(90deg, transparent 0%, var(--white_color));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  display: none
}

@media (max-width: 1023px) {
  .fixedbar_filter-box .filter:before {
    display: block
  }
}

@media (max-width: 767px) {
  .fixedbar_filter-box .filter:before {
    width: 16px
  }
}

.front {
  margin-bottom: 48px
}

@media (max-width: 1279px) {
  .front {
    margin-bottom: 40px
  }
}

@media (max-width: 1023px) {
  .front {
    margin: 0 -24px 40px
  }
}

@media (max-width: 767px) {
  .front {
    margin: 0 -16px 32px
  }
}

.front-cont {
  height: 426px;
  margin-bottom: 8px;
  padding: 23px 144px 58px;
  box-sizing: border-box;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain
}

@media (max-width: 1279px) {
  .front-cont {
    height: 379px;
    margin-bottom: 16px;
    padding: 20px 75px 52px
  }
}

@media (max-width: 1023px) {
  .front-cont {
    height: auto;
    padding: 0;
    background: none
  }
}

@media (max-width: 767px) {
  .front-cont {
    margin-bottom: 24px
  }
}

.front-slider {
  width: 100%;
  overflow: hidden;
  position: relative
}

.front-slider-list {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.front-slider-list__element {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 345px;
  box-sizing: border-box;
  position: relative;
  z-index: 10
}

@media (max-width: 1279px) {
  .front-slider-list__element {
    height: 307px
  }
}

@media (max-width: 1023px) {
  .front-slider-list__element {
    width: 596px;
    height: 781px;
    padding: 32px 29px
  }
}

@media (max-width: 767px) {
  .front-slider-list__element {
    width: 186px;
    height: 372px;
    padding: 20px 15px 18px
  }
}

.front-slider-list__element.swiper-slide-active {
  z-index: 20
}

.front-slider-list__element-screen {
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-position: 50% 50%;
  background-size: cover
}

.front-slider-list__element-screen.desktop {
  display: block
}

@media (max-width: 1023px) {
  .front-slider-list__element-screen.desktop {
    display: none
  }
}

.front-slider-list__element-screen.tablet {
  display: none
}

@media (max-width: 1023px) {
  .front-slider-list__element-screen.tablet {
    display: block
  }
}

@media (max-width: 767px) {
  .front-slider-list__element-screen.tablet {
    display: none
  }
}

.front-slider-list__element-screen.mobile {
  display: none
}

@media (max-width: 767px) {
  .front-slider-list__element-screen.mobile {
    display: block
  }
}

.front-thumb {
  height: 318px;
  position: absolute;
  top: 29px;
  left: -12px;
  overflow: hidden
}

@media (max-width: 1279px) {
  .front-thumb {
    height: 256px;
    top: 55px;
    left: -16px
  }
}

@media (max-width: 1023px) {
  .front-thumb {
    display: none
  }
}

.front-thumb-list {
  width: 80px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 1279px) {
  .front-thumb-list {
    width: 64px
  }
}

.front-thumb-list__item {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 62px;
  height: 62px;
  padding: 3px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
  margin-bottom: 18px;
  position: relative;
  transition: border-color 0.15s ease-in-out
}

@media (max-width: 1279px) {
  .front-thumb-list__item {
    width: 46px;
    height: 46px
  }
}

.front-thumb-list__item-cont {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  z-index: 10;
  box-shadow: var(--dark_shadow)
}

.front-thumb-list__item:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transition: border-color 0.15s ease-in-out
}

.front-thumb-list__item:hover:before, .front-thumb-list__item.active:before {
  border-color: var(--accent)
}

.front-btn {
  text-align: center
}

.front-btn .button {
  vertical-align: top
}

.front-btn .button_primary-bg {
  box-shadow: var(--button_shadow)
}

.front-btn .button_primary-bg:hover {
  box-shadow: none
}

@media (max-width: 1023px) {
  .front-btn .button_primary-bg:hover {
    box-shadow: var(--button_shadow)
  }
}

.description {
  margin-bottom: 48px
}

@media (max-width: 1023px) {
  .description {
    margin-bottom: 40px
  }
}

@media (max-width: 767px) {
  .description {
    margin-bottom: 32px
  }
}

.description-clipped {
  margin-bottom: 48px
}

@media (max-width: 1023px) {
  .description-clipped {
    margin-bottom: 40px
  }
}

.description > *:last-child {
  margin-bottom: 0
}

.masonry {
  margin-bottom: 48px
}

@media (max-width: 1023px) {
  .masonry {
    margin-bottom: 40px
  }
}

@media (max-width: 767px) {
  .masonry {
    margin-bottom: 32px
  }
}

.masonry-slider {
  margin-bottom: 16px;
  position: relative;
  z-index: 10;
  bottom: 0;
  transition: bottom 0.15s ease-in-out
}

@media (max-width: 767px) {
  .masonry-slider {
    margin: 0 -16px -16px;
    padding-bottom: 24px;
    overflow: hidden
  }
}

.masonry-slider:hover {
  bottom: 4px
}

@media (max-width: 1023px) {
  .masonry-slider:hover {
    bottom: 0
  }
}

.masonry-slider-cont {
  margin: -8px -16px -24px;
  padding: 8px 16px 24px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch
}

@media (max-width: 1023px) {
  .masonry-slider-cont {
    margin: -8px -24px -54px;
    padding: 8px 0 54px
  }
}

@media (max-width: 767px) {
  .masonry-slider-cont {
    margin: -8px 0 -54px;
    padding: 8px 0 54px;
    overflow-y: hidden;
    overflow-x: scroll;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch
  }
}

.masonry-slider-list {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 767px) {
  .masonry-slider-list {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: auto;
    padding: 0 16px
  }
}

.masonry-slider-list__element {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%
}

@media (max-width: 1023px) {
  .masonry-slider-list__element {
    width: 596px
  }
}

@media (max-width: 767px) {
  .masonry-slider-list__element {
    width: 288px;
    margin-right: 16px
  }
  .masonry-slider-list__element:last-child {
    margin: 0
  }
}

.masonry-slider-list__element .img-cover-container-rel {
  display: block;
  width: 100%;
  height: 400px;
  background-color: var(--grey);
  box-shadow: var(--mslider_shadow);
  background-position: 0 0;
  background-size: cover;
  border-radius: 18px;
  position: relative;
  text-decoration: none
}

@media (max-width: 1279px) {
  .masonry-slider-list__element .img-cover-container-rel {
    height: 336px
  }
}

@media (max-width: 1023px) {
  .masonry-slider-list__element .img-cover-container-rel {
    height: 264px;
    border-radius: 16px
  }
}

@media (max-width: 767px) {
  .masonry-slider-list__element .img-cover-container-rel {
    height: 186px
  }
}

.masonry-slider-list__element .img-cover-container-rel:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 18px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(0deg, var(--black_light_5) 0%, transparent 35.98%)
}

@media (max-width: 767px) {
  .masonry-slider-list__element .img-cover-container-rel:before {
    background: linear-gradient(0deg, var(--darkened_light), var(--darkened_light))
  }
}

.masonry-slider-list__element-descr {
  position: absolute;
  top: 32px;
  right: 32px;
  bottom: 32px;
  left: 32px;
  z-index: 20;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  color: var(--white_color);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 1023px) {
  .masonry-slider-list__element-descr {
    top: 24px;
    right: 24px;
    bottom: 24px;
    left: 24px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px
  }
}

.masonry-slider-list__element-descr-cont {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.masonry-slider-control {
  width: 100%;
  position: absolute;
  top: 208px;
  right: 0
}

@media (max-width: 1279px) {
  .masonry-slider-control {
    top: 176px
  }
}

@media (max-width: 1023px) {
  .masonry-slider-control {
    top: 140px
  }
}

@media (max-width: 767px) {
  .masonry-slider-control {
    display: none
  }
}

.masonry-slider-control__prev, .masonry-slider-control__next {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  z-index: 30;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: var(--dark_light_2);
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

.masonry-slider-control__prev:hover, .masonry-slider-control__next:hover {
  background-color: var(--primary);
  box-shadow: var(--button_shadow)
}

@media (max-width: 1023px) {
  .masonry-slider-control__prev:hover, .masonry-slider-control__next:hover {
    background-color: var(--dark_light_2);
    box-shadow: none
  }
}

.masonry-slider-control__prev:before, .masonry-slider-control__next:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Eround-arrow_back_white%3C/title%3E%3Cpath d='M19 11H7.83l4.88-4.88a1.008 1.008 0 0 0 0-1.42.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.997.997 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 0-2z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%)
}

.masonry-slider-control__prev {
  left: 16px
}

@media (max-width: 1023px) {
  .masonry-slider-control__prev {
    left: 46px
  }
}

.masonry-slider-control__next {
  right: 16px
}

@media (max-width: 1023px) {
  .masonry-slider-control__next {
    right: 46px
  }
}

.masonry-slider-control__next:before {
  -webkit-transform: translate(50%, -50%) scaleX(-1);
  -ms-transform: translate(50%, -50%) scaleX(-1);
  transform: translate(50%, -50%) scaleX(-1)
}

.masonry-grid {
  margin: -8px -16px -24px;
  padding: 8px 16px 24px;
  position: relative;
  z-index: 20;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 24px
}

@media (max-width: 767px) {
  .masonry-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 16px 0
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) {
  .masonry-grid {
    margin: -8px -24px -24px -16px;
    padding: 8px 0 24px 16px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75) and (max-width: 767px), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) and (max-width: 767px) {
  .masonry-grid {
    margin: -8px -16px -24px;
    padding: 8px 16px 24px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
  }
}

@supports (-ms-ime-align: auto) {
  .masonry-grid {
    margin: -8px -24px -24px -16px;
    padding: 8px 0 24px 16px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch
  }
  @media (max-width: 767px) {
    .masonry-grid {
      margin: -8px -16px -24px;
      padding: 8px 16px 24px;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap
    }
  }
}

.masonry-grid__item {
  -ms-grid-column: span 4;
  grid-column: span 4;
  min-height: 264px;
  border-radius: 18px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  bottom: 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  transition: bottom 0.15s ease-in-out
}

@media (max-width: 1279px) {
  .masonry-grid__item {
    min-height: 336px
  }
}

@media (max-width: 1023px) {
  .masonry-grid__item {
    min-height: 224px;
    border-radius: 16px
  }
}

@media (max-width: 767px) {
  .masonry-grid__item {
    -ms-grid-column: span 6;
    grid-column: span 6;
    min-height: 186px
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) {
  .masonry-grid__item {
    width: calc(33.33% - 24px);
    margin: 0 24px 24px 0
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75) and (max-width: 767px), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) and (max-width: 767px) {
  .masonry-grid__item {
    width: 100%;
    margin: 0 0 16px
  }
}

@supports (-ms-ime-align: auto) {
  .masonry-grid__item {
    width: calc(33.33% - 24px);
    margin: 0 24px 24px 0
  }
  @media (max-width: 767px) {
    .masonry-grid__item {
      width: 100%;
      margin: 0 0 16px
    }
  }
}

.masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
  -ms-grid-column: span 8;
  grid-column: span 8
}

@media (max-width: 767px) {
  .masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
    -ms-grid-column: span 6;
    grid-column: span 6
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) {
  .masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
    width: calc(66.66% - 24px)
  }
}

@media screen and (min-width: 0 \0
) and (-webkit-min-device-pixel-ratio: 0.75) and (max-width: 767px), screen and (min-width: 0 \0
) and (min-resolution: 72dpi) and (max-width: 767px) {
  .masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
    width: 100%
  }
}

@supports (-ms-ime-align: auto) {
  .masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
    width: calc(66.66% - 24px)
  }
  @media (max-width: 767px) {
    .masonry-grid__item:nth-child(7n-6), .masonry-grid__item:nth-child(7n) {
      width: 100%
    }
  }
}

.masonry-grid__item > * {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto
}

.masonry-grid__item:hover {
  bottom: 4px
}

@media (max-width: 1023px) {
  .masonry-grid__item:hover {
    bottom: 0
  }
}

.masonry-grid__item.shadow {
  box-shadow: var(--grid_shadow)
}

@media (max-width: 1023px) {
  .masonry-grid__item.shadow {
    box-shadow: 0 8px 16px var(--black_light_2)
  }
}

.masonry-grid__item.primary-bg {
  background-color: var(--primary);
  box-shadow: var(--button_shadow);
  color: var(--white_color)
}

.masonry-grid__item.primary-bg a {
  color: var(--white_color)
}

.masonry-grid__item.primary-bg .masonry-grid__item-link-info {
  color: var(--white_light_3)
}

.masonry-grid__item a {
  color: var(--dark);
  text-decoration: none
}

.masonry-grid__item.bd-dashed:hover {
  bottom: 0
}

.masonry-grid__item.bd-dashed svg {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  fill: none
}

.masonry-grid__item.bd-dashed svg rect {
  stroke: var(--primary);
  stroke-width: 3;
  stroke-dasharray: 16, 16
}

.masonry-grid__item.bd-solid {
  border: 3px solid var(--grey)
}

.masonry-grid__item.bd-solid .masonry-grid__item-link {
  padding: 29px 21px 21px 29px
}

@media (max-width: 1023px) {
  .masonry-grid__item.bd-solid .masonry-grid__item-link {
    padding: 21px
  }
}

.masonry-grid__item.bd-solid .masonry-grid__item-link.inverted {
  padding: 21px 21px 29px 29px
}

@media (max-width: 1023px) {
  .masonry-grid__item.bd-solid .masonry-grid__item-link.inverted {
    padding: 21px
  }
}

.masonry-grid__item.bd-solid_accent {
  border: 3px solid var(--accent)
}

.masonry-grid__item.bd-solid_accent .masonry-grid__item-link {
  padding: 29px 21px 21px 29px
}

@media (max-width: 1023px) {
  .masonry-grid__item.bd-solid_accent .masonry-grid__item-link {
    padding: 21px
  }
}

.masonry-grid__item.bd-solid_accent .masonry-grid__item-link.inverted {
  padding: 21px 21px 29px 29px
}

@media (max-width: 1023px) {
  .masonry-grid__item.bd-solid_accent .masonry-grid__item-link.inverted {
    padding: 21px
  }
}

.masonry-grid__item-link {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 32px 24px 24px 32px;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 1023px) {
  .masonry-grid__item-link {
    padding: 24px
  }
}

.masonry-grid__item-link.dark {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white_color)
}

.masonry-grid__item-link.dark:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, var(--darkened_light), var(--darkened_light));
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10
}

.masonry-grid__item-link.dark .masonry-grid__item-link-info {
  color: var(--white_light_3)
}

.masonry-grid__item-link.dark .masonry-grid__item-link-info:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Enews%3C/title%3E%3Cpath d='M23.946 8.099h-10.38a.918.918 0 0 0-.646.265.9.9 0 0 0-.268.639v6.005a.9.9 0 0 0 .268.64.918.918 0 0 0 .646.264h10.38a.918.918 0 0 0 .646-.265.9.9 0 0 0 .267-.64V9.004a.9.9 0 0 0-.267-.64.918.918 0 0 0-.646-.264zm-.913 6.035h-8.554V9.907h8.554z' fill='%23fff'/%3E%3Cpath d='M29.335 6.712a2.699 2.699 0 0 0-.803-1.918A2.754 2.754 0 0 0 26.595 4H10.918a2.754 2.754 0 0 0-1.938.794 2.699 2.699 0 0 0-.802 1.918v3.779h-2.77a2.754 2.754 0 0 0-1.938.794 2.699 2.699 0 0 0-.802 1.918v11.181a3.599 3.599 0 0 0 1.07 2.558A3.672 3.672 0 0 0 6.32 28h18.66a4.375 4.375 0 0 0 3.079-1.262 4.288 4.288 0 0 0 1.275-3.048zM8.178 24.384a1.814 1.814 0 0 1-.54 1.29 1.855 1.855 0 0 1-2.604 0 1.814 1.814 0 0 1-.54-1.29V13.203a.9.9 0 0 1 .268-.64.918.918 0 0 1 .646-.264h2.77zm19.36-.693a2.489 2.489 0 0 1-.74 1.769 2.54 2.54 0 0 1-1.786.732H9.487a3.588 3.588 0 0 0 .487-1.808V6.712a.9.9 0 0 1 .267-.639.918.918 0 0 1 .646-.265h15.708a.918.918 0 0 1 .646.265.9.9 0 0 1 .267.64z' fill='%23fff'/%3E%3Cpath d='M23.946 17.72h-10.38a.918.918 0 0 0-.646.265.898.898 0 0 0 0 1.278.918.918 0 0 0 .646.265h10.38a.918.918 0 0 0 .646-.265.898.898 0 0 0 0-1.278.918.918 0 0 0-.646-.265zM23.946 21.337h-10.38a.918.918 0 0 0-.646.264.898.898 0 0 0 0 1.279.918.918 0 0 0 .646.265h10.38a.918.918 0 0 0 .646-.265.898.898 0 0 0 0-1.279.918.918 0 0 0-.646-.264z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-grid__item-link.dark .masonry-grid__item-link-info:before {
    width: 24px;
    height: 24px
  }
}

.masonry-grid__item-link > * {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: block;
  width: 100%;
  box-sizing: border-box
}

.masonry-grid__item-link > *:last-child {
  margin-top: auto
}

.masonry-grid__item-link-text {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  overflow: hidden;
  font-size: 20px;
  font-weight: bold;
  line-height: 26px
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px
  }
}

.masonry-grid__item-link-info {
  color: var(--dark_light_1);
  position: relative;
  z-index: 20;
  padding: 6px 32px 6px 0
}

.masonry-grid__item-link-info:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eoutline-info_white%3C/title%3E%3Cpath d='M17.335 9.333h-2.667V12h2.667zM17.335 14.667h-2.667v8h2.667z' fill='%23fff'/%3E%3Cpath d='M16.001 2.667A13.333 13.333 0 1 0 29.335 16 13.338 13.338 0 0 0 16 2.667zm0 24A10.667 10.667 0 1 1 26.668 16a10.68 10.68 0 0 1-10.667 10.667z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-info:before {
    width: 24px;
    height: 24px
  }
}

.masonry-grid__item-link-info.quote:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eround-format_quote_white%3C/title%3E%3Cpath d='M9.559 22.667a1.784 1.784 0 0 0 1.6-.987l1.893-3.787a2.632 2.632 0 0 0 .28-1.186v-6.04a1.337 1.337 0 0 0-1.333-1.334H6.665a1.337 1.337 0 0 0-1.333 1.334V16a1.337 1.337 0 0 0 1.333 1.333h2.667L7.959 20.08a1.785 1.785 0 0 0 1.6 2.587zm13.333 0a1.784 1.784 0 0 0 1.6-.987l1.893-3.787a2.633 2.633 0 0 0 .28-1.186v-6.04a1.337 1.337 0 0 0-1.333-1.334h-5.333a1.337 1.337 0 0 0-1.334 1.334V16A1.337 1.337 0 0 0 20 17.333h2.666l-1.373 2.747a1.785 1.785 0 0 0 1.6 2.587z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-info.quote:before {
    width: 24px;
    height: 24px
  }
}

.masonry-grid__item-link-info.star:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eround-star_border%3C/title%3E%3Cpath d='M26.2 12.053l-6.454-.56-2.52-5.933a1.334 1.334 0 0 0-2.453 0l-2.52 5.947-6.44.546a1.33 1.33 0 0 0-.76 2.334l4.893 4.24-1.467 6.293a1.33 1.33 0 0 0 1.987 1.44l5.533-3.333 5.533 3.346a1.33 1.33 0 0 0 1.987-1.44l-1.467-6.306 4.894-4.24a1.328 1.328 0 0 0-.747-2.334zM16 20.533l-5.014 3.027 1.333-5.707-4.427-3.84 5.84-.506L16 8.133l2.28 5.387 5.84.507-4.426 3.84 1.333 5.706z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-info.star:before {
    width: 24px;
    height: 24px
  }
}

.masonry-grid__item-link-info.news:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Enews%3C/title%3E%3Cpath d='M23.946 8.099h-10.38a.918.918 0 0 0-.646.265.9.9 0 0 0-.268.639v6.005a.9.9 0 0 0 .268.64.918.918 0 0 0 .646.264h10.38a.918.918 0 0 0 .646-.265.9.9 0 0 0 .267-.64V9.004a.9.9 0 0 0-.267-.64.918.918 0 0 0-.646-.264zm-.913 6.035h-8.554V9.907h8.554z' fill='%23222'/%3E%3Cpath d='M29.335 6.712a2.699 2.699 0 0 0-.803-1.918A2.754 2.754 0 0 0 26.595 4H10.918a2.754 2.754 0 0 0-1.938.794 2.699 2.699 0 0 0-.802 1.918v3.779h-2.77a2.754 2.754 0 0 0-1.938.794 2.699 2.699 0 0 0-.802 1.918v11.181a3.599 3.599 0 0 0 1.07 2.558A3.672 3.672 0 0 0 6.32 28h18.66a4.375 4.375 0 0 0 3.079-1.262 4.288 4.288 0 0 0 1.275-3.048zM8.178 24.384a1.814 1.814 0 0 1-.54 1.29 1.855 1.855 0 0 1-2.604 0 1.814 1.814 0 0 1-.54-1.29V13.203a.9.9 0 0 1 .268-.64.918.918 0 0 1 .646-.264h2.77zm19.36-.693a2.489 2.489 0 0 1-.74 1.769 2.54 2.54 0 0 1-1.786.732H9.487a3.588 3.588 0 0 0 .487-1.808V6.712a.9.9 0 0 1 .267-.639.918.918 0 0 1 .646-.265h15.708a.918.918 0 0 1 .646.265.9.9 0 0 1 .267.64z' fill='%23222'/%3E%3Cpath d='M23.946 17.72h-10.38a.918.918 0 0 0-.646.265.898.898 0 0 0 0 1.278.918.918 0 0 0 .646.265h10.38a.918.918 0 0 0 .646-.265.898.898 0 0 0 0-1.278.918.918 0 0 0-.646-.265zM23.946 21.337h-10.38a.918.918 0 0 0-.646.264.898.898 0 0 0 0 1.279.918.918 0 0 0 .646.265h10.38a.918.918 0 0 0 .646-.265.898.898 0 0 0 0-1.279.918.918 0 0 0-.646-.264z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-info.news:before {
    width: 24px;
    height: 24px
  }
}

.masonry-grid__item-link-sectional {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 20
}

.masonry-grid__item-link-sectional > *:last-child {
  margin-bottom: 0
}

.masonry-grid__item-link-sectional-tx {
  display: block;
  margin-bottom: 19px;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px
}

@media (max-width: 1023px) {
  .masonry-grid__item-link-sectional-tx {
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 26px
  }
}

@media (max-width: 767px) {
  .masonry-grid__item-link-sectional-tx {
    margin-bottom: 17px
  }
}

.masonry-grid__item-link-sectional-foot {
  display: block
}

.masonry-grid__item-link-sectional-title {
  display: block;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.5px
}

.masonry-grid__item-link-sectional-content {
  display: block;
  font-size: 12px;
  line-height: 16px
}

.masonry-grid__item-link.inverted {
  padding: 24px 24px 32px 32px
}

@media (max-width: 1023px) {
  .masonry-grid__item-link.inverted {
    padding: 24px
  }
}

.masonry-grid__item-link.inverted .masonry-grid__item-link-info:before {
  top: 0;
  bottom: auto
}

.masonry-grid__item-link.transparent {
  background-color: transparent
}

.masonry-grid__item-link.transparent .masonry-grid__item-link-info {
  color: var(--dark)
}

.masonry-grid__item-list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 29px 21px 21px 29px
}

@media (max-width: 1023px) {
  .masonry-grid__item-list {
    padding: 21px
  }
}

.masonry-grid__item-list ul li {
  margin-bottom: 16px
}

.masonry-grid__item-list ul li:before {
  display: none
}

.masonry-grid__item-list ul li:last-child {
  margin-bottom: 0
}

.masonry-grid__item-list ul li a {
  color: var(--primary);
  text-decoration: underline
}

.masonry-grid__item-list ul li a:hover {
  color: var(--brown);
  text-decoration: none
}

@media (max-width: 1023px) {
  .masonry-grid__item-list ul li a:hover {
    color: var(--primary);
    text-decoration: underline
  }
}

.masonry-grid__item-top {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 100%;
  padding: 32px 24px 32px 32px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

.masonry-grid__item-top > svg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.masonry-grid__item-top.dark {
  background-size: cover
}

.masonry-grid__item-top.dark:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, var(--darkened_light), var(--darkened_light));
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10
}

.masonry-grid__item-top > * {
  display: block;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.masonry-grid__item-top > *:last-child {
  margin-top: auto
}

.masonry-grid__item-top-cont {
  position: relative
}

.masonry-grid__item-top-title {
  font: 500 20px/26px 'Roboto', sans-serif;
  letter-spacing: normal;
  color: var(--white_color);
  position: relative;
  z-index: 20
}

.masonry-grid__item-top-title-b {
  display: block;
  margin-bottom: 16px;
  font-size: 72px;
  line-height: normal
}

.masonry-grid__item-top-info {
  color: var(--white_light_3);
  position: relative;
  z-index: 20
}

.masonry-block {
  width: auto;
  max-width: 696px;
  margin: 48px auto;
  padding: 24px 24px 32px 32px;
  box-sizing: border-box;
  background-color: var(--primary);
  border-radius: 18px;
  box-shadow: var(--button_shadow)
}

@media (max-width: 1023px) {
  .masonry-block {
    max-width: none;
    margin: 40px 0;
    padding: 24px
  }
}

@media (max-width: 767px) {
  .masonry-block {
    margin: 32px 0
  }
}

.\masonry-block-head {
  margin-bottom: 15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-style: oblique;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start
}

.masonry-block-head h3 {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  margin: 0;
  padding-top: 4px;
  color: var(--white_color)
}

@media (max-width: 1023px) {
  .masonry-block-head h3 {
    padding: 0
  }
}

.masonry-block-icon {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eround-star_white%3C/title%3E%3Cpath d='M16 23.025l5.533 3.347a1.33 1.33 0 0 0 1.986-1.44l-1.467-6.294 4.894-4.24a1.33 1.33 0 0 0-.76-2.333l-6.44-.547-2.52-5.946a1.334 1.334 0 0 0-2.454 0l-2.52 5.933-6.44.547a1.33 1.33 0 0 0-.76 2.333l4.894 4.24-1.467 6.293a1.33 1.33 0 0 0 1.987 1.44z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-block-icon {
    width: 24px;
    height: 24px
  }
}

.masonry-block-btn .button {
  vertical-align: top;
  margin-right: 16px
}

.masonry-block-btn .button:last-child {
  margin-right: 0
}

.masonry-block_v2 {
  background-color: var(--white_color);
  border: 3px solid var(--accent);
  padding: 21px 21px 29px 29px;
  box-shadow: none
}

@media (max-width: 1023px) {
  .masonry-block_v2 {
    padding: 21px
  }
}

.masonry-block_v2 h3 {
  color: var(--brown)
}

.masonry-block_v2 .masonry-block-icon {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Ctitle%3Eround-star_brown%3C/title%3E%3Cpath d='M16 23.025l5.533 3.347a1.33 1.33 0 0 0 1.986-1.44l-1.467-6.294 4.894-4.24a1.33 1.33 0 0 0-.76-2.333l-6.44-.547-2.52-5.946a1.334 1.334 0 0 0-2.454 0l-2.52 5.933-6.44.547a1.33 1.33 0 0 0-.76 2.333l4.894 4.24-1.467 6.293a1.33 1.33 0 0 0 1.987 1.44z' fill='%23453536'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .masonry-block_v2 .masonry-block-icon {
    width: 24px;
    height: 24px
  }
}

.games {
  margin: 0 -24px 48px;
  padding: 16px 24px 0;
  overflow: hidden
}

@media (max-width: 1279px) {
  .games {
    margin-bottom: 40px
  }
}

@media (max-width: 767px) {
  .games {
    margin: 0 0 32px;
    padding: 0;
    overflow: visible
  }
}

.games-list {
  width: calc(100% + 24px);
  margin-bottom: 16px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 1023px) {
  .games-list {
    margin-bottom: 8px
  }
}

@media (max-width: 767px) {
  .games-list {
    width: 100%;
    margin-bottom: 0
  }
}

.games-list .game {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: calc(33.33% - 24px);
  margin: 0 24px 32px 0
}

@media (max-width: 1279px) {
  .games-list .game {
    margin: 0 24px 24px 0
  }
}

@media (max-width: 1023px) {
  .games-list .game {
    width: calc(50% - 24px)
  }
}

@media (max-width: 767px) {
  .games-list .game {
    width: 100%;
    margin: 0 0 24px
  }
}

.games-list .game-img {
  box-shadow: var(--game_shadow)
}

.popular {
  margin: 48px 0
}

@media (max-width: 1279px) {
  .popular {
    margin: 40px 0
  }
}

@media (max-width: 767px) {
  .popular {
    margin: 32px 0
  }
}

.popular-head {
  margin-bottom: 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline
}

@media (max-width: 767px) {
  .popular-head {
    margin-bottom: 32px
  }
}

.popular-head .h101 {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  width: 100%;
  margin: 0
}

@media (max-width: 1279px) {
  .popular-head .h101 {
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px
  }
}

.popular-head-info {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 24px;
  color: var(--dark_light_2)
}

@media (max-width: 767px) {
  .popular-head-info {
    display: none
  }
}

.notification {
  width: 312px;
  padding: 12px 36px 12px 12px;
  box-sizing: border-box;
  background-color: var(--accent);
  border-radius: 16px;
  cursor: pointer;
  position: fixed;
  right: 16px;
  bottom: 0;
  z-index: 670;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out
}

@media (max-width: 767px) {
  .notification {
    width: auto;
    padding: 12px 34px 12px 10px;
    right: 16px;
    left: 16px;
    -webkit-transform: translate(0, 100%);
    -ms-transform: translate(0, 100%);
    transform: translate(0, 100%)
  }
}

.notification:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Eround-arrow_back_white%3C/title%3E%3Cpath d='M19 11H7.83l4.88-4.88a1.008 1.008 0 0 0 0-1.42.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.997.997 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 0-2z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%) scaleX(-1);
  -ms-transform: translateY(-50%) scaleX(-1);
  transform: translateY(-50%) scaleX(-1)
}

@media (max-width: 767px) {
  .notification:before {
    right: 10px
  }
}

.notification .header-assist {
  width: auto;
  height: auto;
  padding: 0;
  position: static;
  background-color: transparent;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.notification .header-assist:before, .notification .header-assist:after {
  display: none
}

.notification .header-assist-ava {
  width: 40px;
  height: 40px;
  margin-right: 16px
}

@media (max-width: 767px) {
  .notification .header-assist-ava {
    width: 32px;
    height: 32px;
    margin-right: 13px
  }
}

.notification .header-assist-info {
  display: block;
  padding: 0
}

.notification .header-assist-info-name {
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  margin: 0;
  color: var(--dark);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

@media (max-width: 767px) {
  .notification .header-assist-info-name {
    margin-bottom: 2px
  }
}

.notification .header-assist-info-status {
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  color: var(--dark_light_2);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.popup-game {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 670;
  background: var(--backgounds_color);
  visibility: hidden;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  transition: -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media screen and (orientation: landscape) {
  .popup-game {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
  }
}

.popup-game-panel {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding: 0 24px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 767px) {
  .popup-game-panel {
    height: 48px;
    padding: 0 16px
  }
}

@media screen and (orientation: landscape) {
  .popup-game-panel {
    height: 100%;
    width: 56px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 24px 0
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
  .popup-game-panel {
    width: 48px;
    padding: 16px 0
  }
}

.popup-game-panel > * {
  display: block;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-order: 20;
  -ms-flex-order: 20;
  order: 20
}

.popup-game-panel .button {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 36px;
  padding: 0 12px;
  line-height: 32px;
  white-space: nowrap
}

@media screen and (orientation: landscape) {
  .popup-game-panel .button {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
  }
}

.popup-game-panel .header-assist {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: auto;
  height: auto;
  position: static;
  padding: 0
}

@media screen and (orientation: landscape) {
  .popup-game-panel .header-assist {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10
  }
}

.popup-game-panel .header-assist:before, .popup-game-panel .header-assist:after {
  display: none
}

.popup-game-panel .header-assist-ava {
  width: 40px;
  height: 40px;
  margin: 0
}

@media (max-width: 767px) {
  .popup-game-panel .header-assist-ava {
    width: 32px;
    height: 32px
  }
}

.popup-game-panel .header-assist-info {
  display: none
}

.popup-game-play {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  position: relative;
  width: 100%;
  height: 100%
}

.popup-game-close {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Eclose_icon%3C/title%3E%3Cpath d='M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.997.997 0 0 0 5.7 7.11L10.59 12 5.7 16.89a.997.997 0 0 0 1.41 1.41L12 13.41l4.89 4.89a.997.997 0 0 0 1.41-1.41L13.41 12l4.89-4.89a1.002 1.002 0 0 0 0-1.4z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 16px;
  border: none
}

@media (max-width: 767px) {
  .popup-game-close {
    margin-right: 8px
  }
}

@media screen and (orientation: landscape) {
  .popup-game-close {
    margin: 16px 0 0;
    -webkit-order: 30;
    -ms-flex-order: 30;
    order: 30
  }
}

@media screen and (orientation: landscape) and (max-width: 767px) {
  .popup-game-close {
    margin-top: 8px
  }
}

.alert-modal {
  margin: 24px 0;
  padding: 32px;
  background: var(--white_color);
  box-shadow: var(--dark_shadow);
  border-radius: 18px;
  position: relative
}

@media (max-width: 1279px) {
  .alert-modal {
    margin: 32px 0
  }
}

@media (max-width: 1023px) {
  .alert-modal {
    padding: 16px
  }
}

.alert-modal:before {
  position: absolute;
  top: 28px;
  left: 24px
}

@media (max-width: 1023px) {
  .alert-modal:before {
    top: 17px;
    left: 16px
  }
}

.alert-modal h4 {
  margin-bottom: 24px;
  padding: 0 40px
}

@media (max-width: 1023px) {
  .alert-modal h4 {
    margin-bottom: 16px
  }
}

.alert-modal-content > *:last-child {
  margin-bottom: 0
}

.alert-modal_error_blue:before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: contain
}

@media (max-width: 1023px) {
  .alert-modal_error_blue:before {
    width: 24px;
    height: 24px
  }
}

.filter {
  width: 100%;
  overflow: hidden;
  margin: -8px 0 32px
}

@media (max-width: 1023px) {
  .filter {
    width: auto;
    height: 36px;
    overflow: hidden;
    margin: 0 -24px 32px
  }
}

@media (max-width: 767px) {
  .filter {
    margin: 0 -16px 24px
  }
}

@supports (-ms-ime-align: auto) {
}

@supports (-ms-ime-align: auto) {
}

.aligncenter {
  display: block;
  margin: 48px auto;
  border-radius: 18px;
  box-shadow: var(--dark_shadow)
}

@media (max-width: 767px) {
  .aligncenter {
    width: 100%;
    margin: 32px auto
  }
}

.table-wrap {
  overflow: hidden;
  margin: 28px 0 48px
}

@media (max-width: 1023px) {
  .table-wrap {
    margin: 40px 0
  }
}

@media (max-width: 767px) {
  .table-wrap {
    margin: 32px -16px 32px
  }
}

.table-scroll {
  -webkit-overflow-scrolling: touch
}

@media (max-width: 767px) {
  .table-scroll {
    padding-bottom: 30px;
    margin-bottom: -30px;
    overflow-x: scroll;
    overflow-y: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch
  }
}

.table-cont {
  box-sizing: border-box
}

@media (max-width: 767px) {
  .table-cont {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 0 16px
  }
}

@media (max-width: 1023px) {
  .table-cont table {
    max-width: none
  }
}

.counters {
  width: 100%;
  overflow: hidden;
  margin: 48px 0
}

@media (max-width: 1023px) {
  .counters {
    margin: 40px 0
  }
}

@media (max-width: 767px) {
  .counters {
    margin: 32px 0
  }
}

.counters-list {
  width: calc(100% + 24px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch
}

@media (max-width: 767px) {
  .counters-list {
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

.counters-list__item {
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: calc(33.33% - 24px);
  margin-right: 24px;
  padding: 28px 32px;
  border-radius: 18px;
  background-color: var(--primary);
  color: var(--white_color);
  box-sizing: border-box;
  text-align: center
}

@media (max-width: 767px) {
  .counters-list__item {
    width: 100%;
    padding: 16px;
    margin: 0 0 24px
  }
}

@media (max-width: 767px) {
  .counters-list__item:last-child {
    margin: 0
  }
}

.counters-list__item_v1 {
  background-color: var(--brown)
}

.counters-list__item_v2 {
  background-color: var(--accent);
  color: var(--brown)
}

.counters-list__item__numb {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 600;
  line-height: 58px
}

.counters-list__item__txt {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal
}

.button_up {
  display: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--grey);
  border: none;
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 300;
  transition: background-color 0.15s ease-in-out
}

.button_up:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Eround-arrow_back_white%3C/title%3E%3Cpath d='M19 11H7.83l4.88-4.88a1.008 1.008 0 0 0 0-1.42.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.997.997 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 0-2z' fill='%23222'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%) rotate(90deg);
  -ms-transform: translate(50%, -50%) rotate(90deg);
  transform: translate(50%, -50%) rotate(90deg);
  transition: background 0.15s ease-in-out
}

.button_up:hover {
  background-color: var(--primary)
}

.button_up:hover:before {
  background: url("data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Ctitle%3Eround-arrow_back_white%3C/title%3E%3Cpath d='M19 11H7.83l4.88-4.88a1.008 1.008 0 0 0 0-1.42.996.996 0 0 0-1.41 0l-6.59 6.59a.996.996 0 0 0 0 1.41l6.59 6.59a.997.997 0 0 0 1.41-1.41L7.83 13H19a1 1 0 0 0 0-2z' fill='%23fff'/%3E%3C/svg%3E") no-repeat 50% 50%;
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px
}
