@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

//@font-face {
//  font-family: Lato;
//  src: url('../fonts/Lato-Italic.woff2') format("woff2"), url('../fonts/Lato-Italic.woff') format("woff");
//  font-weight: 400;
//  font-style: italic;
//  font-display: swap
//}

@font-face {
  font-family: Lato;
  src: url(../fonts/Lato-Regular.woff2) format("woff2"), url(../fonts/Lato-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

//@font-face {
//  font-family: Lato;
//  src: url(../fonts/Lato-SemiboldItalic.woff2) format("woff2"), url(../fonts/Lato-SemiboldItalic.woff) format("woff");
//  font-weight: 600;
//  font-style: italic;
//  font-display: swap
//}

@font-face {
  font-family: Lato;
  src: url(../fonts/Lato-Bold.woff2) format("woff2"), url(../fonts/Lato-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}
//
//@font-face {
//  font-family: Lato;
//  src: url(../fonts/Lato-BoldItalic.woff2) format("woff2"), url(../fonts/Lato-BoldItalic.woff) format("woff");
//  font-weight: 700;
//  font-style: italic;
//  font-display: swap
//}

@font-face {
  font-family: Lato;
  src: url(../fonts/Lato-Semibold.woff2) format("woff2"), url(../fonts/Lato-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

//.header-assist:before {
//  background-image: url(../images/gradient_cover.png)
//}

.masonry-grid__item-link.dark, .masonry-grid__item-top.dark {
  background-image: url(../images/game_b.png)
}

.front-slider-list__element .front-slider-list__element-mobile, .front-slider-list__element .front-slider-list__element-tablet {
  display: none
}

@media (max-width: 1023px) {
  .front-cont > picture:first-child {
    display: none
  }
  .front-slider-list__element .front-slider-list__element-tablet {
    display: block
  }
}

@media (max-width: 767px) {
  .front-slider-list__element .front-slider-list__element-mobile {
    display: block
  }
  .front-slider-list__element .front-slider-list__element-tablet {
    display: none
  }
}

ul > li:before {
  background: none;
  width: 6px;
  height: 6px;
  border: 2px solid #3DD4DB;
  border-radius: 50%;
}

.alert-modal_error_blue:before {
  background-image: url(../images/round-error_outline_blue.svg);
}

@media (max-width: 1024px) {
  .alert-modal_error_blue:before {
    background-image: url(../images/round-error_outline_blue_sm.svg)
  }
}
